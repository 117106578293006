import 'jquery';
import 'jquery-migrate';
import 'bootstrap-sass/assets/javascripts/bootstrap';

import "et-line";

import 'animate.css';
import "./css/style.scss";
import "./index.scss";

import 'html5shiv';
import 'scrollit';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';

import 'magnific-popup/dist/magnific-popup.css'
import 'magnific-popup';

import 'bootstrap-validator'
import { load } from 'recaptcha-v3'


$(function () {
    "use strict";

    var wind = $(window);


    $.scrollIt({
        upKey: 38,                // key code to navigate to the next section
        downKey: 40,              // key code to navigate to the previous section
        easing: 'swing',         // the easing function for animation
        scrollTime: 600,          // how long (in ms) the animation takes
        activeClass: 'active',    // class given to the active nav element
        onPageChange: null,       // function(pageIndex) that is called when page is changed
        topOffset: -60            // offste (in px) for fixed top navigation
    });
    // close navbar-collapse when a  clicked
    $(".nav a").on('click', function () {
        $(".navbar-collapse").removeClass("in").addClass("collapse");
    });


    // navbar scrolling background
    wind.on("scroll", function () {

        var bodyScroll = wind.scrollTop(),
            navbar = $(".navbar-default")

        if (bodyScroll > 300) {

            navbar.addClass("nav-scroll");

        } else {

            navbar.removeClass("nav-scroll");
        }
    });


    $('.experience .owl-carousel').owlCarousel({
        margin: 30,
        loop: true,
        autoplay: false,
        mouseDrag: false,
        smartSpeed: 500,
        nav: true,
        navText: ["<i class='fas fa-caret-left'></i>", "<i class='fas fa-caret-right'></i>"],
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            767: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    });

    // magnificPopup
    $('.experience .pitem').magnificPopup({
        type: 'ajax',
        delegate: 'a',
        alignTop: true
    });

    $('a.ajax-popup').magnificPopup({
        type: 'ajax',
        alignTop: true
    });
});


$(window).on("load", function () {


    // contact form
    $('#contact-form').validator();

    $('#contact-form').on('submit', function (e) {
        var form = $(this);

        if (e.isDefaultPrevented())
            return false;

        form.find("i[data-role='form-submit-icon']").removeClass("hidden")

        e.preventDefault();

        load('6LeCjtIUAAAAAGKeSK5-cBZHmnG5UiHG00Cdvz1S', {
            autoHideBadge: true
        }).then((recaptcha) => {
            function formatErrorMessage(jqXHR, exception) {

                if (jqXHR.status === 0) {
                    return ('Not connected.\nPlease verify your network connection.');
                } else if (jqXHR.status == 404) {
                    return ('The requested page could not be found. [404]');
                } else if (jqXHR.status == 500) {
                    return ('An internal error has occured on the remote server. [500].');
                } else if (exception === 'parsererror') {
                    return ('The response received from the server could not be understood.');
                } else if (exception === 'timeout') {
                    return ('A timeout occured.');
                } else if (exception === 'abort') {
                    return ('The request was aborted.');
                } else {
                    return ('Uncaught Error.\n' + jqXHR.responseText);
                }
            }

            recaptcha.execute('submit_contact_form').then((token) => {
                form.prepend("<input type='hidden' name='g-recaptcha-response' value='" + token + "'>");

                $.ajax({
                    type: "POST",
                    url: form.attr("action"),
                    data: form.serialize(),
                    error: function (xhr, ajaxOptions, thrownError) {
                        var messageText = "Uw bericht kon helaas niet worden verzonden, er is een technische fout opgetreden. (Details: " + formatErrorMessage(xhr, thrownError) + ")"
                        var alertBox = '<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + messageText + '</div>';

                        $('#contact-form').find('.messages').html(alertBox);

                        form.find("i[data-role='form-submit-icon']").addClass("hidden")
                        form.find("input[name='g-recaptcha-response']").remove()
                    },

                    success: function (data) {
                        var messageAlert = 'alert-' + data.type;
                        var messageText = data.message;

                        var alertBox = '<div class="alert ' + messageAlert + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + messageText + '</div>';
                        if (messageAlert && messageText) {
                            $('#contact-form').find('.messages').html(alertBox);
                            $('#contact-form')[0].reset();
                        }

                        form.find("i[data-role='form-submit-icon']").addClass("hidden")
                        form.find("input[name='g-recaptcha-response']").remove()
                    }
                });

            });
        });
    });
});